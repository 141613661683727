import './image-upload.scss';
import { ReactComponent as Delete } from '@/assets/svgs/delete.svg';
import { ReactComponent as View } from '@/assets/svgs/view.svg';
import { ReactComponent as LeftArrow } from '@/assets/svgs/left-arrow.svg';
import { ReactComponent as Download } from '@/assets/svgs/Download-svg.svg';
import { ReactComponent as Camera } from '@/assets/svgs/camera.svg';
import { useEffect, useMemo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';
import { FreeMode, Scrollbar } from 'swiper/modules';
import Modal from 'react-bootstrap/Modal';

interface ImageFiles {
  credit_image?: string[];
  credit_note_image?: string[];
  cheque_image?: string[];
  online_image?: string[];
  image?: string[];
}
interface UploadImageProps {
  setisImageUpload: (value: boolean) => void; // State updater for isImageUpload
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    name: string,
    index: number
  ) => void; // File change handler
  fileImage: ImageFiles;
  setFileImage: (newImage: any) => void;
  imageName: string; // Name of the file input
}
const UploadImage: React.FC<UploadImageProps> = ({
  setisImageUpload,
  handleChange,
  fileImage,
  setFileImage,
  imageName,
}) => {
  const [imageList, setImageList] = useState<string[]>([]);
  const [imageTitle, setImageTitle] = useState<string>('');
  const [isNewImage, setIsNewImage] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [isActiveDeleteModal, setIsActiveDeleteModal] =
    useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<number>(0);

  const isMediumScreen = useMemo<boolean>(() => {
    return windowWidth >= 900;
  }, [windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const titles = {
      image: 'Image',
      online_image: 'Online',
      cheque_image: 'Cheque',
      credit_image: 'Credit',
      credit_note_image: 'Credit Note',
    }
    
    setImageTitle(titles[imageName as keyof ImageFiles])
    setImageList(fileImage[imageName as keyof ImageFiles] || []);
  }, [fileImage, imageName])

  const handleUpload = () => {
    setisImageUpload(false);
  };

  const handleImageChange = () => {
    let img = document.getElementById('upload-image');
    img?.click();
  };

  const imageKey = imageName;

  // checking image is exist    
  const hasImage = Boolean(imageList.length);

  // delete selected image
  function handleDeleteImage() {
    setFileImage({
      ...fileImage,
      [imageKey]: imageList.filter(
        (img: any, imgIdx: number) => imgIdx !== selectedImage
      ),
    });

    setSelectedImage(0);
    setIsActiveDeleteModal(false);
  }

  return (
    <>
      <Modal
        show={isActiveDeleteModal}
        onHide={() => setIsActiveDeleteModal(false)}
        centered
        className="custom-delete-image-modal"
      >
        <Modal.Body className="container">
          <div className="icon-container">
            <div className="icon">
              <View />
            </div>
          </div>
          <div className="content-container">
            <p>
              Are you sure you want to <br /> delete the Image?
            </p>
          </div>
          <div className="action-container">
            <button
              type="button"
              className="btn-cancel"
              onClick={() => setIsActiveDeleteModal(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn-delete"
              onClick={() => handleDeleteImage()}
            >
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <input
        type="file"
        id="upload-image"
        className="d-none"
        name={imageName}
        onChange={(e) => {
          if (e.target.value) {
            let imgIndex = isNewImage ? 
            imageList.length : selectedImage;

            handleChange(e, imageName, imgIndex);
            setIsNewImage(false);
            setTimeout(() => {
            setSelectedImage(imgIndex);
            }, 200);
          }
        }}
      />
      <div
        className="custom-upload-image-container"
        style={{
          height: isMediumScreen
            ? `calc(${window.innerHeight}px - 30px)`
            : window.innerHeight,
        }}
      >
        <div className="header">
          <div className="d-flex">
            <div className="back-link" onClick={handleUpload}>
              <LeftArrow />
            </div>
            <span>
              {imageTitle !== '' ? imageTitle : 'Upload image'}
            </span>
          </div>
          <div className="download-btn-div">
            <a href={imageList[selectedImage]} download={imageKey}>
              <Download />
            </a>
          </div>
        </div>
        <div className="body">
          <div className="image-container">
            <div className="position-relative">
              <div className="image">
                <div className="upload-image-div">
                  {Boolean(hasImage) ? (
                    <>
                      <img
                        src={hasImage ? imageList[selectedImage] : ''}
                        className="img-fluid h-100 w-100"
                        alt=""
                      />
                      <div className="img-overlay"></div>
                    </>
                  ) : (
                    <div
                      className="upload-image-child"
                      onClick={() => handleImageChange()}
                    >
                      <div className="text-center">
                        <Camera />
                        <p>Camera</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {hasImage && hasImage ? (
                <div
                  className="close-btn-div"
                  onClick={() => setIsActiveDeleteModal(true)}
                >
                  <Delete />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="image-list-container">
            {imageList.length ? (
              <Swiper
                slidesPerView={'auto'}
                scrollbar={false}
                freeMode={true}
                modules={[FreeMode, Scrollbar]}
                className={`swiper-container ${
                  imageList.length > 3
                    ? 'swiper-container-left'
                    : 'swiper-container-center'
                }`}
                direction={'horizontal'}
              >
                {imageList.map((item: any, index: number) => {
                  return (
                    <SwiperSlide
                      className={`image-item ${
                        imageList.length - 1 === index
                          ? ''
                          : 'right-space'
                      }`}
                      key={index}
                      onClick={() => setSelectedImage(index)}
                    >
                      {item !== '' ? (
                        <img
                          src={item}
                          alt="image-item"
                          className={`img ${
                            selectedImage === index ? 'active' : ''
                          }`}
                        />
                      ) : null}
                    </SwiperSlide>
                  );
                })}

                <SwiperSlide
                  className="image-item add-btn left-space"
                  onClick={() => {
                    setIsNewImage(true);
                    setTimeout(() => {
                      handleImageChange();
                    }, 200);
                  }}
                >
                  <span>+</span>
                </SwiperSlide>
              </Swiper>
            ) : null}
          </div>
        </div>
        <div className="footer">
          <button
            type="button"
            className="w-100 btn-retake me-1"
            onClick={() => handleImageChange()}
          >
            Retake
          </button>
          {Boolean(imageList) ? (
            <button
              type="button"
              className="w-100 btn-save"
              onClick={handleUpload}
            >
              Save
            </button>
          ) : (
            <button
              type="button"
              className="w-100 btn-save"
              style={{ background: '#98A2B3', color: 'white' }}
            >
              Save
            </button>
          )}
        </div>
      </div>
    </>
  );
};
export default UploadImage;
